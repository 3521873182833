import * as React from "react";
import { WizardVRPWAProps } from "./typings";
import { Location } from "src/components/shared/Location/Location";
import { observer } from "mobx-react";
import { SubmitButton } from "src/components/shared/SubmitButton/SubmitButton";
import { Travelers } from "src/components/shared/Travelers/Travelers";
import { Dates } from "src/components/shared/Dates/Dates";
import { LobTitle } from "src/components/shared/LobTitle/LobTitle";
import { EGDSLayoutGrid, EGDSLayoutGridItem, EGDSLayoutGridSpan } from "@egds/react-core/layout-grid";
import { NoRehydratationValues, TravelersTriggerTypes } from "src/components/shared/TravelersField/typings";
import { Viewport, ViewSmall, ViewMedium, ViewLarge } from "@shared-ui/viewport-context";
import { EGDSSpacing, EGDSSpacingProps } from "@egds/react-core/spacing";
import { EGDSLayoutFlex, EGDSLayoutFlexItem } from "@egds/react-core/layout-flex";
import { SubmitRow } from "src/components/shared/StorefrontWizard/SubmitRow/SubmitRow";
import { Action } from "src/components/utility/analytics/FlexAnalyticsUtils";
import { VRAddPWPToggle } from "components/flexComponents/WizardVRPWA/components/VRAddPWPToggle";
import { Experiment, ExperimentControl, ExperimentVariant } from "@shared-ui/experiment-context";
import { VrPropertyType, VR_PROPERTY_TYPES_ALL } from "stores/wizard/config/typings";

export const WizardVRPWAView: React.FunctionComponent<WizardVRPWAProps> = observer((props) => {
  const {
    globalWizardState,
    vrWizardState,
    vrWizardState: { config: vrConfig },
  } = props.wizardState;

  const { templateComponent, page, cookies } = props;
  const { config } = templateComponent;

  const globalConfig = props.wizardState.globalWizardState.config;
  const { halfWizard } = globalConfig || {};
  const { searchButtonAlongsideDates = true } = vrConfig;

  const locationColSpan = {
    small: 2 as EGDSLayoutGridSpan,
    medium: halfWizard ? 6 : (4 as EGDSLayoutGridSpan),
    large: halfWizard ? 6 : searchButtonAlongsideDates ? 2 : (5 as EGDSLayoutGridSpan),
  };

  /**
   * Values used if no rehydratation(JS disabled)
   */
  const noRehydratationValues: NoRehydratationValues = {
    numOfTravelers: 2,
    name: "adults",
  };
  const datesColSpan = {
    small: 2 as EGDSLayoutGridSpan,
    medium: halfWizard ? 6 : (2 as EGDSLayoutGridSpan),
    large: halfWizard ? 6 : searchButtonAlongsideDates ? 2 : (4 as EGDSLayoutGridSpan),
  };
  const travelersColSpan = {
    small: 2 as EGDSLayoutGridSpan,
    medium: searchButtonAlongsideDates ? 1 : (2 as EGDSLayoutGridSpan),
    large: searchButtonAlongsideDates ? 1 : (3 as EGDSLayoutGridSpan),
  };
  const submitColSpan = { small: 2 as EGDSLayoutGridSpan, medium: 1 as EGDSLayoutGridSpan };
  const setInputsRefs = (ref: React.RefObject<HTMLInputElement>) => {
    vrWizardState.wizardInput = ref;
  };

  const travelersInlinePadding: EGDSSpacingProps["padding"] = { block: "three" };

  const renderTravelers = (triggerType?: TravelersTriggerTypes) => {
    return (
      <Travelers
        lobState={vrWizardState}
        colSpan={travelersColSpan}
        noRehydratationValues={noRehydratationValues}
        triggerType={triggerType}
      />
    );
  };

  const pageName = page ? page.pageName : "unknown";
  const moduleName = halfWizard ? "HalfBiasedWizard" : "FullBiasedWizard";
  const linkName = "VRSearch";
  const sectionName = "SQM";
  const rfrrid = `${pageName}.${moduleName}.${linkName}.${sectionName}`;
  const extraTracking: any = {
    schemaName: "referrer",
    messageContent: {
      referrerId: rfrrid,
      eventType: Action.CLICK,
    },
  };
  const isAgent = cookies?.getCookie("actx") ?? undefined;
  const displayPWPToggleOnVR = (
    <Viewport>
      <ViewSmall>
        <EGDSLayoutFlex justifyContent={"start"}>
          <VRAddPWPToggle wizardState={props.wizardState} />
        </EGDSLayoutFlex>
      </ViewSmall>
      <ViewMedium>
        <EGDSLayoutFlex justifyContent={"start"}>
          <VRAddPWPToggle wizardState={props.wizardState} />
        </EGDSLayoutFlex>
      </ViewMedium>
      <ViewLarge>
        <EGDSLayoutGridItem colSpan={datesColSpan}>
          <EGDSLayoutFlexItem>
            <EGDSLayoutFlex justifyContent="end">
              <VRAddPWPToggle wizardState={props.wizardState} />
            </EGDSLayoutFlex>
          </EGDSLayoutFlexItem>
        </EGDSLayoutGridItem>
      </ViewLarge>
    </Viewport>
  );
  const useRewardsValue = [String(vrWizardState.isPayWithPointsChecked) ?? false];
  const vrPropertyTypes: VrPropertyType[] =
    vrConfig?.vrPropertyTypes?.length > 0 ? vrConfig.vrPropertyTypes : VR_PROPERTY_TYPES_ALL;

  return (
    <form
      noValidate
      className="WizardVRPWA"
      id={vrConfig.elementId}
      data-fm={config.fmId}
      data-fm-title-id={config.fmTitleId}
      action={vrConfig.form.action}
      onSubmit={vrWizardState.submit}
    >
      {!config.displayVacationRentalsOnly && (
        <input type="hidden" name="sort" value="VACATION_RENTAL" data-testid="sort-input" />
      )}
      {config.displayVacationRentalsOnly && (
        <>
          <input type="hidden" name="vacationRentalsOnly" value="true" data-testid="display-vr-only" />
          {vrPropertyTypes.map((vrPropertyType: string) => (
            <input
              type="hidden"
              name="lodging"
              value={vrPropertyType}
              key={`vr-type-${vrPropertyType}`}
              data-testid={`vr-type-${vrPropertyType}`}
            />
          ))}
        </>
      )}
      <LobTitle lobState={vrWizardState} />
      <Viewport>
        <ViewSmall>
          <>
            {halfWizard && (
              <EGDSSpacing padding={travelersInlinePadding}>
                {renderTravelers(TravelersTriggerTypes.LINK_BUTTON)}
              </EGDSSpacing>
            )}
          </>
        </ViewSmall>
        <ViewMedium>
          <EGDSSpacing padding={travelersInlinePadding}>
            <EGDSLayoutFlex data-testid="travelers-inline-link" alignItems="center" justifyContent="space-between">
              {halfWizard && (
                <EGDSLayoutFlexItem>
                  <div>{renderTravelers(TravelersTriggerTypes.LINK_BUTTON)}</div>
                </EGDSLayoutFlexItem>
              )}
            </EGDSLayoutFlex>
          </EGDSSpacing>
        </ViewMedium>
      </Viewport>
      <EGDSSpacing padding={searchButtonAlongsideDates ? { block: "three" } : { blockstart: "three" }}>
        <EGDSLayoutGrid
          columns={{ small: 2, medium: halfWizard ? 6 : 4, large: searchButtonAlongsideDates ? 6 : 12 }}
          space="three"
        >
          <Location
            setInputsRefs={setInputsRefs}
            originField={false}
            destinationField
            lobState={vrWizardState}
            colSpan={locationColSpan}
            globalConfig={globalWizardState.config}
          />
          <Dates lobState={vrWizardState} colSpan={datesColSpan} />
          {!halfWizard && renderTravelers()}
          {!halfWizard && searchButtonAlongsideDates && (
            <SubmitButton
              lobState={vrWizardState}
              colSpan={submitColSpan}
              globalConfig={globalConfig}
              uisPrimeMicroMessages={extraTracking}
            />
          )}
        </EGDSLayoutGrid>
      </EGDSSpacing>
      {vrConfig.enableVRPWPToggle && (
        <Experiment name="ETS_POINTS_TOGGLE">
          <ExperimentControl />
          <ExperimentVariant bucket={1}>
            {isAgent && (
              <Experiment name="Delta_BSA_Loyalty_Toggle">
                <ExperimentControl />
                <ExperimentVariant bucket={1}>{displayPWPToggleOnVR}</ExperimentVariant>
              </Experiment>
            )}
            {!isAgent && displayPWPToggleOnVR}
            <input type="hidden" name="useRewards" value={useRewardsValue} data-testid="display-vr-only" />
          </ExperimentVariant>
        </Experiment>
      )}
      {(halfWizard || !searchButtonAlongsideDates) && (
        <SubmitRow
          lobState={vrWizardState}
          rfrr="SearchClick_vacationRental"
          extraUISTracking={extraTracking}
          globalConfig={globalConfig}
        />
      )}
    </form>
  );
});

export default WizardVRPWAView;
